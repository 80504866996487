<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getProductTags()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createTagModal = { modal: true }">{{ $t('create_tag') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <b slot="color" slot-scope="props" class="badge badge-pill" :style="'background-color: ' + $_.find(colorOptions, option => { return option.value === props.row.color }).colorCode">
              {{ $_.find(colorOptions, option => { return option.value === props.row.color }).label }}
            </b>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editTagModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteTagModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateTagModal :data="createTagModal.data" :show="createTagModal.modal" @showUpdate="show => createTagModal.modal = show" />

      <EditTagModal :data="editTagModal.data" :show="editTagModal.modal" @showUpdate="show => editTagModal.modal = show" />

      <DeleteTagModal :data="deleteTagModal.data" :show="deleteTagModal.modal" @showUpdate="show => deleteTagModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CreateTagModal from './modal/CreateTagModal'
import EditTagModal from './modal/EditTagModal'
import DeleteTagModal from './modal/DeleteTagModal'

export default {
  name: 'tags',
  components: {
    CreateTagModal,
    EditTagModal,
    DeleteTagModal
  },
  data () {
    return {
      columns: [ 'name_en', 'name_zh', 'color', 'action' ],
      data: [],
      options: {
        headings: {
          name_en: this.$t('name') + ' (EN)',
          name_zh: this.$t('name') + ' (ZH)',
          color: this.$t('color'),
          action: this.$t('action')
        },
        sortable: [ 'name_en', 'name_zh', 'color' ],
        filterable: [ 'name_en', 'name_zh' ]
      },
      colorOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('color') + '...', colorCode: '#FFFFFF' },
        { value: 'primary', label: 'primary (#3D7582)', colorCode: '#3D7582' },
        { value: 'secondary', label: 'secondary (#868e96)', colorCode: '#868e96' },
        { value: 'success', label: 'success (#28a745)', colorCode: '#28a745' },
        { value: 'info', label: 'info (#17a2b8)', colorCode: '#17a2b8' },
        { value: 'warning', label: 'warning (#ffd65a)', colorCode: '#ffd65a' },
        { value: 'danger', label: 'danger (#dc3545)', colorCode: '#dc3545' },
        { value: 'light', label: 'light (#f8f9fa)', colorCode: '#f8f9fa' },
        { value: 'dark', label: 'dark (#343a40)', colorCode: '#343a40' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      createTagModal: { data: {}, modal: false },
      editTagModal: { data: {}, modal: false },
      deleteTagModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getProductTags () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getProductTags', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
