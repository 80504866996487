<template>
  <CModal
    :title="$t('edit_tag')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CTabs :fill="true" variant="tabs" :fade="false" :active-tab="0" addNavWrapperClasses="pb-4">
      <CTab title="EN">
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name') + ' (EN)'"
              :placeholder="$t('name') + ' (EN)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.name_en"
              :isValid="valid_formData_name_en"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('color')"
              :options="colorOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.color"
              :isValid="valid_formData_color"
            />
          </CCol>
        </CRow>
      </CTab>
      <CTab title="ZH">
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name') + ' (ZH)'"
              :placeholder="$t('name') + ' (ZH)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.name_zh"
              :isValid="valid_formData_name_zh"
            />
          </CCol>
        </CRow>
      </CTab>
    </CTabs>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditTagModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      colorOptions: [
        { value: 'primary', label: 'primary (#3D7582)', colorCode: '#3D7582' },
        { value: 'secondary', label: 'secondary (#868e96)', colorCode: '#868e96' },
        { value: 'success', label: 'success (#28a745)', colorCode: '#28a745' },
        { value: 'info', label: 'info (#17a2b8)', colorCode: '#17a2b8' },
        { value: 'warning', label: 'warning (#ffd65a)', colorCode: '#ffd65a' },
        { value: 'danger', label: 'danger (#dc3545)', colorCode: '#dc3545' },
        { value: 'light', label: 'light (#f8f9fa)', colorCode: '#f8f9fa' },
        { value: 'dark', label: 'dark (#343a40)', colorCode: '#343a40' }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showProductTag()
      }
    }
  },
  computed: {
    valid_formData_name_en () {
      return this.formData.name_en ? true : false
    },
    valid_formData_name_zh () {
      return this.formData.name_zh ? true : false
    },
    valid_formData_color () {
      return this.formData.color ? true : false
    },
    valid_submit () {
      return this.valid_formData_name_en && this.valid_formData_name_zh && this.valid_formData_color
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showProductTag () {
      const loader = this.$loading.show()
      this.$store.dispatch('showProductTag', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateProductTag', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
